.file-upload {
    text-align: center;
    border: 2px dashed #4485B7;
    padding: 1.5rem;
    position: relative;
    cursor: pointer;
    border-radius: 0.75rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    background-color: #3994FE10;
}
  .file-upload p {
    font-size: 0.87rem;
    margin-top: 10px;
    color: #bbcada;
  }
  .file-upload input {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
  }